import PropTypes from "prop-types";
import SourceFlowImage from "@sourceflow-uk/sourceflowimage";
import imagesMetaData from "@/../.sourceflow/image_metadata.json";
import { useMediaQuery } from "@react-hook/media-query";

/**
 *
 * @param className
 * @param img
 * @param size
 * @param alt
 * @returns {JSX.Element|null}
 * @constructor
 */
export default function Image({ className = "", img = null, size = null, alt = null }) {

  const xs = useMediaQuery("only screen and (max-width: 540px)");

  if (!img) {
    return null;
  }

  if (typeof img === "string") {
    return <SourceFlowImage className={className} src={img} size={size} alt={alt} />;
  }

  if (typeof img === "object") {
    if ("path" in img) {
      return <SourceFlowImage className={className} path={img.path} size={size} imagesMetaData={imagesMetaData} />;
    }

    if (xs && "xs" in img && img.xs) {
      return <SourceFlowImage className={className} src={img.xs} alt={img.alt ?? "tbd"} size={size} />;
    }

    if ("src" in img && img.src) {
      return <SourceFlowImage className={className} src={img.src} alt={img.alt ?? "tbd"} size={size} />;
    }
  }

  return null;
}

Image.propTypes = {
  className: PropTypes.string,
  img: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      path: PropTypes.string,
    }),
    PropTypes.shape({
      src: PropTypes.string,
      alt: PropTypes.string,
    }),
  ]),
  size: PropTypes.string,
  alt: PropTypes.string,
};
