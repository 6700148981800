import { lazy } from "react";
export { default as SiteHeader } from "./SiteHeader";

export const Body = lazy(() => import("./Body"));
export const Content = lazy(() => import("./Content"));
export const DynamicHtml = lazy(() => import("./DynamicHtml"));
export const DynamicText = lazy(() => import("./DynamicText"));
export const Form = lazy(() => import("./Form"));
export const GoogleJobsSchema = lazy(() => import("./GoogleJobsSchema"));
export const Image = lazy(() => import("./Image"));
export const SiteFooter = lazy(() => import("./SiteFooter"));
export const Title = lazy(() => import("./Title"));