import { useEffect, useState } from "react";
import classes from "./styles.module.scss";
import Clock from "react-clock";
import { toZonedTime } from "date-fns-tz";
import 'react-clock/dist/Clock.css';

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
export const Clocks = ({theme}) => {
  const [value, setValue] = useState(new Date());
  const [props] = useState({
    size: 49,
    hourMarksLength: 0,
    hourMarksWidth: 0,
    minuteMarksWidth: 0,
    minuteMarksLength: 0,
    hourHandWidth: 2,
    hourHandLength: 60,
    minuteHandWidth: 1.5,
    minuteHandLength: 80
  })

  useEffect(() => {
    const interval = setInterval(() => setValue(new Date()), 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className={classes.clocks} data-theme={theme}>
      <dl>
        <dt>New York</dt>
        <dd><Clock value={toZonedTime(value, "America/New_York")} {...props} /></dd>
      </dl>
      <dl>
        <dt>Boston</dt>
        <dd><Clock value={toZonedTime(value, "America/New_York")} {...props} /></dd>
      </dl>
      <dl>
        <dt>London</dt>
        <dd><Clock value={toZonedTime(value, "Europe/London")} {...props} /></dd>
      </dl>
      <dl>
        <dt>Paris</dt>
        <dd><Clock value={toZonedTime(value, "Europe/Paris")} {...props} /></dd>
      </dl>
      <dl>
        <dt>San Francisco</dt>
        <dd><Clock value={toZonedTime(value, "America/Los_Angeles")} {...props} /></dd>
      </dl>
    </div>
  )
}