import "@/scss/styles.scss";
import { getGlobal } from "@/functions/getGlobal";
import { Suspense } from "react";
import { SiteFooter, SiteHeader } from "@/ui";
import SourceFlowHead from "@sourceflow-uk/sourceflow-head";
import metaObject from "../../.sourceflow/metadata.json";
import CookieConsent from '@/components/Shared/CookieConsent';
import SourceFlowTracker from "@sourceflow-uk/sourceflow-tracker";

export default function App({ Component, pageProps }) {
  const global = getGlobal();
  const gtag = global["_theme.google.gtag"];

  return (
    <Suspense>
      <SourceFlowHead metaObject={metaObject} addTracker={true} cookieExpiryTime={3000}>
        {pageProps?.meta?.title && <title>{pageProps.meta.title}</title>}
      </SourceFlowHead>
      <SiteHeader className={global["_theme.header.classes"]} />
      <main className="flex-grow-1">
        <Component {...pageProps} />
      </main>
      <SiteFooter />
      {/* <ReciteMeWidget /> */}
      <CookieConsent>
        {gtag && (
          <>
            <script id="gtm" src={`https://www.googletagmanager.com/gtag/js?id=${gtag}`} />
            <script id="gtm_script" dangerouslySetInnerHTML={{ __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', '${gtag}');
              `}}
            />
          </>
        )}
        <SourceFlowTracker cookieExpiryTime={3000} />
      </CookieConsent>
    </Suspense>
  );
}
