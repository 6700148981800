import data from "../../.sourceflow/main_menu.json";
import BaseCollection from "@sourceflow-uk/sourceflow-sdk/base_collection";

export const getHeaderMenu = (locale = "en") => {
  const collection = new BaseCollection(data, locale).getItems();

  return collection
    .filter((i) => i.parent.id === null)
    .map((i) => {
      const children = collection.filter((c) => c.parent.id === i.id);

      return children ? { ...i, children } : i;
    });
};
